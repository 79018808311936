<template>
  <div id="game-group">
    <games-topic topic="體驗趣" 
    src="game/group/expirence" type="left"
    feature="可置入影片或多張圖，呈現產品或服務的豐富度設定限額限量，讓需要聲量的新產品運用體驗網頁索取使用，同時打造用戶體驗而產生回購率。"
    :applys="[
    '課程試聽',
    'SPA 體驗服務',
    '保養品試用索取',
    '面膜試用索取',
    '現場產品試吃體驗',
    '醫美體驗',
    '其他服務業體驗索取皆可']"/>
    <games-topic topic="團購趣" 
    src="game/group/buying" type="right"
    feature="是一種對商品或服務集氣的概念。當人們喜歡一種商品，若此商品有50人參與興趣，對於購買行為會大大加分。"
    :applys="[
    '課程試聽',
    'SPA 體驗服務',
    '保養品試用索取',
    '面膜試用索取',
    '現場產品試吃體驗',
    '醫美體驗',
    '其他服務業體驗索取皆可']"/>
  </div>
</template>

<script>
import gamesTopic from "@/components/gamesTopic.vue";

export default {
  components: {
    gamesTopic
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
#game-group {
  .gamesTopic {
    margin: 4% 0;
  }
}
</style>
