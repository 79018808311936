<template>
  <div id="game-crazy">
    <h1>Coming Soon!</h1>
  </div>
</template>

<script>
import gamesTopic from "@/components/gamesTopic.vue";

export default {
  components: {
    gamesTopic
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
#game-crazy {
  h1 {
    font-weight: 300;
    @include font(bigTitle);
    margin: 10% 0;
  }
}
</style>
