<template>
  <div class="game">
    <header>
      <top-title
      title="GAME"
      subTitle="遊戲模組">
      </top-title>
    </header>
    <div class="container">
      <page-main-body
        title="簡易與瘋狂的遊戲類型帶動行銷遊戲化"
        subTitle="Easy And Crazy Game Types Inspires Marketing Gamifaction"
        paragraph1="投遞廣告、網紅直播、口碑論壇等數位行銷模式是否感到一成不變呢!難道遊戲只能是宅男腐女的樂趣嗎? 其實不然,透過遊戲的互動樂趣潛移默化消費者對品牌的印象感，運用一系列的機制設定從遊戲中被行銷。為此，我們秉持著研發一系列遊行行銷模組為品牌與企業帶來全新的數位行銷模式。">
      </page-main-body>
    </div>
    <h2>各式各樣的遊戲模組</h2>
    <div id="innerNav">
      <div class="navTopic NG" @mouseover="navHov" @mouseout="navHovOut" @click="chooseNav(1)">Group</div>
      <div class="navTopic NE" @mouseover="navHov" @mouseout="navHovOut" @click="chooseNav(2)" style="color:#9d4dce">Easy</div>
      <div class="navTopic NC" @mouseover="navHov" @mouseout="navHovOut" @click="chooseNav(3)">Crazy</div>
      <hr id="navUnderLine">
    </div>
    <div id="game-pages">
      <game-group v-if="currentTopic == 1"/>
      <game-easy v-if="currentTopic == 2"/>
      <game-crazy v-if="currentTopic == 3"/>
    </div>
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import gameGroup from "@/components/gameGroup.vue";
import gameEasy from "@/components/gameEasy.vue";
import gameCrazy from "@/components/gameCrazy.vue";

export default {
  name: "game",
  components: {
    topTitle,
    pageMainBody,
    gameGroup,
    gameEasy,
    gameCrazy
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
    this.$parent.isChildPage = false;
  },
  mounted() {
    this.$parent.isChildPage = true;
    this.navHovOut();
  },
  data() {
    return {
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false,
      currentTopic: 2
    };
  },
  methods: {
    navHov(e) {
      $("#navUnderLine").width($(e.target).width() * 0.9);
      var left =
        $(e.target).offset().left -
        $("#innerNav").offset().left +
        ($(e.target).width() * (1 - 0.9)) / 2;
      $("#navUnderLine").css({ left: left + "px" });
    },
    navHovOut() {
      $("#navUnderLine").css({
        left:
          $(".navTopic")[this.currentTopic - 1].offsetLeft +
          ($(".navTopic")[this.currentTopic - 1].clientWidth * (1 - 0.9)) / 2 +
          "px",
        "background-color": this.getColor()
      });
      $("#navUnderLine").width(
        $(".navTopic")[this.currentTopic - 1].clientWidth * 0.9
      );
    },
    handleResize() {
      this.navHovOut();
    },
    chooseNav(idx) {
      this.currentTopic = idx;
      $(".navTopic").css({ color: "#707070" });
      $(".navTopic")[this.currentTopic - 1].style.color = this.getColor();
    },
    getColor() {
      var currentColor;
      switch (this.currentTopic) {
        case 1:
          currentColor = "#f12379";
          break;
        case 2:
          currentColor = "#9d4dce";
          break;
        default:
          currentColor = "#73cccf";
      }
      return currentColor;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/style.scss";
header {
  background-image: url("../../assets/image/products/game/topBanner.jpg");
}
.game {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2,
  h3 {
    margin: 4% 0;
  }
  h2 {
    @include font(large);
  }
  h3 {
    @include font(medium);
  }
  #innerNav {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 4% 0;
    .navTopic {
      cursor: pointer;
      margin: 0 20%;
      transition: 0.3s;
      @include font(medium);
      font-weight: 300;
      white-space: nowrap;
      &:hover {
        &.NG {
          color: $mainRed !important;
          & ~ #navUnderLine {
            background-color: $mainRed !important;
          }
        }
        &.NE {
          color: $mainPurple !important;
          & ~ #navUnderLine {
            background-color: $mainPurple !important;
          }
        }
        &.NC {
          color: $mainBlue !important;
          & ~ #navUnderLine {
            background-color: $mainBlue !important;
          }
        }
        & ~ div {
          color: #707070 !important;
        }
      }
    }
    #navUnderLine {
      position: absolute;
      margin: 0;
      height: 1px;
      bottom: -10px;
      transition: 0.3s;
      @media #{$phone} {
        bottom: -5px;
      }
    }
  }
  #game-pages {
    width: 80vw;
    max-width: 1200px;
  }
}
</style>
